export default function () {
  const config = useRuntimeConfig()
  const { authentication } = useApi()
  const { user } = useUser()

  const isAuthenticated = computed(() => !!user.value)

  async function logout(): Promise<void> {
    if (!isAuthenticated.value) {
      return
    }

    await authentication.logout()
    user.value = null

    await navigateTo(config.public.api.redirects.onLogout)
  }

  return {
    user,
    isAuthenticated,
    logout,
  }
}
